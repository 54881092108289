import React, {useState, useEffect} from 'react';
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Text,
  Flex,
  Avatar,
  List,
  ListItem
} from "@chakra-ui/react";
import Card from "./card/Card";
import {OikosAdmin} from "../SDK/OikosAdmin";

interface UserSelectorProps {
  type: 'educator' | 'learner';
  selectedUser: OIKOS.user | null;
  handleSelectUser: (type: string, user: OIKOS.user) => void;
}

export const UserSelector: React.FC<UserSelectorProps> = ({type, selectedUser, handleSelectUser}) => {
  const admin = new OikosAdmin();

  const [inputValue, setInputValue] = useState('');
  const [users, setUsers] = useState<OIKOS.user[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<OIKOS.user[]>([]);

  useEffect(() => {
    const fetchUsers = async () => {
      const fetchedUsers = type === 'learner' ? await admin.listLearners() : await admin.listEducators();
      setUsers(fetchedUsers);
    };
    fetchUsers();
  }, [type]);

  useEffect(() => {
    setFilteredUsers(users.filter(user => {
      const fullName = `${user.Name} ${user.FamilyName}`;
      return fullName.toLowerCase().includes(inputValue.toLowerCase());
    }));
  }, [inputValue, users]);

  useEffect(() => {
    if(selectedUser) {
      setInputValue('');
    }
  }, [selectedUser])


  return (
    <FormControl>
      {!selectedUser && <FormLabel>Select {type}</FormLabel>}
      <Box position="relative">
        {!selectedUser && <Input placeholder={`Search ${type}`} value={inputValue}
                                 onChange={(e) => setInputValue(e.target.value)}/>}
        {inputValue && (
          <List position="absolute" bg="white" maxH="200px" overflowY="auto" w="100%" boxShadow="md"
                zIndex="1">
            {filteredUsers.map((user, index) => (
              <ListItem key={index} onClick={() => handleSelectUser(type, user)}
                        cursor={"pointer"}>
                <Flex direction={"row"}>
                  <Avatar
                    src={`data:image/jpeg;base64,${user.Avatar}`}
                    w={"30px"}
                    h={"30px"}
                    m={"5px"}
                  />
                  <Text fontWeight={"bold"} mt={"10px"}>{user.Name} {user.FamilyName}</Text>
                </Flex>
              </ListItem>
            ))}
          </List>
        )}
        {selectedUser && (
          <Card bg={"gray.600"} mt={"20px"} shadow={"lg"}>
            <Flex direction={"row"} onClick={() => handleSelectUser(type, null)}>
              <Avatar
                src={`data:image/jpeg;base64,${selectedUser.Avatar}`}
                w={"60px"}
                h={"60px"}
                m={"5px"}
              />
              <Text fontWeight={"bold"} fontSize={20} m={"15px"} color={"gray.100"}>
                {selectedUser.Name} {selectedUser.FamilyName}
              </Text>
            </Flex>
          </Card>
        )}
      </Box>
    </FormControl>
  );
};
