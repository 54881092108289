import React, { useState } from 'react';
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  Button,
  Stack,
  Flex
} from '@chakra-ui/react';
import { UserSelector } from "./UserSelector";
import {OikosAdmin} from "../SDK/OikosAdmin";
import {notification} from "antd";

interface AddMatchDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  refresh: () => void;
}

const AddMatchDrawer: React.FC<AddMatchDrawerProps> = ({ isOpen, onClose, refresh }) => {
  const [selectedUsers, setSelectedUsers] = useState({ learner: null as OIKOS.user | null, educator: null as OIKOS.user | null });

  const admin = new OikosAdmin();

  const handleSelectUser = (type: string, user: OIKOS.user | null) => setSelectedUsers({ ...selectedUsers, [type]: user });

  const handleSubmit = () => {
    if (selectedUsers.learner && selectedUsers.educator) {
      admin.addMatch(selectedUsers.educator.ID, selectedUsers.learner.ID)
        .then(() => {
          notification.info({ message: "Match created successfully" });
          setSelectedUsers({ learner: null, educator: null });
          onClose();
          refresh();
        })
        .catch((error) => {
          notification.error({ message: "Error creating match", description: error.message });
        })
    }
  }

  return (
    <Drawer isOpen={isOpen} onClose={onClose} size={"lg"}>
      <DrawerOverlay>
        <DrawerContent>
          <DrawerHeader>Create New Match</DrawerHeader>
          <DrawerBody>
            <Stack spacing={6}>
              <UserSelector type="learner" selectedUser={selectedUsers.learner} handleSelectUser={handleSelectUser} />
              {selectedUsers.learner &&
                <UserSelector type="educator" selectedUser={selectedUsers.educator} handleSelectUser={handleSelectUser} />
              }
              {selectedUsers.learner && selectedUsers.educator &&
                <Flex justifyContent="flex-end" marginTop="4">
                  <Button colorScheme="blue" onClick={handleSubmit}>Create Match</Button>
                </Flex>
              }
            </Stack>
          </DrawerBody>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
}

export default AddMatchDrawer;
