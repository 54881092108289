import React, { useState } from "react";
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Input,
  FormControl,
  FormLabel,
  Button,
  Select,
} from "@chakra-ui/react";
import {OikosAdmin} from "../SDK/OikosAdmin";
import {notification} from "antd";

interface AddUserDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  refresh: () => void;
}

const AddUserDrawer: React.FC<AddUserDrawerProps> = ({ isOpen, onClose, refresh }) => {
  const [name, setName] = useState("");
  const [familyName, setFamilyName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [userType, setUserType] = useState("B2B_LEARNER"); // default value

  const admin = new OikosAdmin();

  const handleSubmit = () => {
    // Submit the form data to your API or database.
    // console.log(formData);
    admin.newUser(name, familyName, email, password, userType).then(() => {
      notification.info({ message: "User created successfully" });
      refresh();
      onClose();
    }).catch((error) => {
      notification.error({ message: "Error creating user", description: error.message });
    })
  };

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
      <DrawerOverlay>
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Add a new user</DrawerHeader>

          <DrawerBody>
            <FormControl id="name">
              <FormLabel>Name</FormLabel>
              <Input name="name" value={name} onChange={(event) => {setName(event.target.value)}} />
            </FormControl>

            <FormControl id="familyName" mt={4}>
              <FormLabel>Family Name</FormLabel>
              <Input name="familyName" value={familyName} onChange={(event) => {setFamilyName(event.target.value)}} />
            </FormControl>

            <FormControl id="email" mt={4}>
              <FormLabel>Email</FormLabel>
              <Input name="email" value={email} onChange={(event) => {setEmail(event.target.value)}} />
            </FormControl>

            <FormControl id="password" mt={4}>
              <FormLabel>Password</FormLabel>
              <Input type="password" name="password" value={password} onChange={(event) => {setPassword(event.target.value)}} />
            </FormControl>

            <FormControl id="role" mt={4}>
              <FormLabel>Role</FormLabel>
              <Select name="role" value={userType} onChange={(event) => {setUserType(event.target.value)}}>
                <option value="B2B_LEARNER">Learner</option>
                <option value="EDUCATOR">Educator</option>
              </Select>
            </FormControl>
          </DrawerBody>

          <DrawerFooter>
            <Button variant="outline" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme="blue" onClick={handleSubmit}>
              Submit
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
};

export default AddUserDrawer;
