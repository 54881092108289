// Chakra imports
import {Box, Button, SimpleGrid} from '@chakra-ui/react';
import MatchesTable from 'views/admin/matches/MatchesTable';
import AddMatchDrawer from 'components/AddMatchDrawer';
import {useState} from "react";

export default function Matches() {
  // Chakra Color Mode
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [refreshKey, setRefreshKey] = useState(0);

  const refreshData = () => {
    const newKey = refreshKey + 1;
    setRefreshKey(newKey);
  }

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };


  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>

      <AddMatchDrawer isOpen={isDrawerOpen} onClose={handleDrawerClose} refresh={refreshData}/>
      <SimpleGrid mb='20px' columns={{ sm: 1, md: 1 }} spacing={{ base: '20px', xl: '20px' }}>
        <Button onClick={handleDrawerOpen}>Create New Match</Button>
        <MatchesTable refreshKey={refreshKey}/>
      </SimpleGrid>
    </Box>
  );
}
