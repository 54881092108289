import React from 'react';
import ReactDOM from 'react-dom';
import './assets/css/App.css';
import { App } from './App';
import { ProvideAuth } from "./Hooks/useAuth";
import { ChakraProvider } from '@chakra-ui/react';
import theme from './theme/theme';

ReactDOM.render(
	<ChakraProvider theme={theme}>
		<React.StrictMode>
			<ProvideAuth>
				<App />
			</ProvideAuth>
		</React.StrictMode>
	</ChakraProvider>,
	document.getElementById('root')
);
