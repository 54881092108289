

// Chakra imports
import { Box, SimpleGrid, Button } from '@chakra-ui/react';
import SessionsTable from 'views/admin/sessions/SessionsTable';
import AdminNewSessionDrawer from "components/AdminNewSessionDrawer";
import { useState } from 'react';

export default function Sessions() {
  // Chakra Color Mode

  const [isDrawerOpen, setDrawerOpen] = useState(false);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };


  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>

      <AdminNewSessionDrawer isOpen={isDrawerOpen} onClose={handleDrawerClose} />
      <SimpleGrid mb='20px' columns={{ sm: 1, md: 1 }} spacing={{ base: '20px', xl: '20px' }}>
        <Button onClick={handleDrawerOpen}>Schedule new session</Button>
        <SessionsTable  />
      </SimpleGrid>
    </Box>
  );
}
