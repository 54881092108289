import React, { useState, useCallback } from 'react';

interface VideoPlayerProps {
  recordingSid: string;
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({ recordingSid }) => {
  const [videoUrl, setVideoUrl] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);



  //TODO: Use fetchRecording
  const fetchVideo = useCallback(async () => {
    setLoading(true);
    setError(null);

    try {
      const API_ENDPOINT = process.env.REACT_APP_API_URL || "http://localhost:8090";
      const response = await fetch(API_ENDPOINT + '/admin/video/composition/get', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // Include other headers such as Authorization if needed
        },
        body: JSON.stringify({ CompositionSid: recordingSid }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      setVideoUrl(url);
    } catch (e) {
      if (e instanceof Error) {
        setError(`Failed to load video: ${e.message}`);
      } else {
        setError('An unexpected error occurred');
      }
    } finally {
      setLoading(false);
    }
  }, [recordingSid]);

  const handleStart = () => {
    if (!videoUrl) { // If the video hasn't been fetched yet
      fetchVideo();
    }
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      {!videoUrl && (
        <button onClick={handleStart} disabled={loading}>
          {loading ? 'Loading...' : 'Start Streaming'}
        </button>
      )}
      {videoUrl && (
        <video controls width="620">
          <source src={videoUrl} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      )}
    </div>
  );
};

export default VideoPlayer;
