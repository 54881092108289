import React, { useState } from 'react';
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  FormControl,
  FormLabel,
  Input,
  Button, useToast,
} from '@chakra-ui/react';
import {OikosAdmin} from "../../../SDK/OikosAdmin";

type ChangePasswordDrawerProps = {
  email: string;
  isOpen: boolean;
  onClose: () => void;
};

const ChangePasswordDrawer: React.FC<ChangePasswordDrawerProps> = ({email, isOpen, onClose}) => {
  const [password, setPassword] = useState<string>("");

  const toast = useToast();
  const admin = new OikosAdmin();

  const handleChangePassword = () => {
    // API call for changing the password
    // After the API call is successful, close the drawer and show the toast
    admin.changeUserPassword(email, password)
      .then((res: any) => {
        onClose();
        setPassword("")
        toast({
          title: "Password Changed.",
          description: "The password has been successfully changed.",
          status: "success",
          duration: 3000,
          isClosable: true,
        })
      }).catch((err: any) => {
      setPassword("")
      toast({
        title: "Error",
        description: "There was an error changing the password.",
        status: "error",
        duration: 3000,
        isClosable: true,
      })
    })

  };


  return (
    <Drawer isOpen={isOpen} onClose={onClose} size="md">
      <DrawerOverlay>
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Change Password for {email}</DrawerHeader>
          <DrawerBody>
            <FormControl>
              <FormLabel>New Password</FormLabel>
              <Input
                type="password"
                placeholder="Enter new password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </FormControl>
          </DrawerBody>
          <DrawerFooter>
            <Button variant="outline" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme="blue" onClick={handleChangePassword}>
              Change Password
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
};

export default ChangePasswordDrawer;
