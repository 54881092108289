import {Box, Heading, SimpleGrid, Stack} from "@chakra-ui/react";
import React from "react";
import Card from 'components/card/Card';
import HorizonBarChart from "./HorizonBarChart";
import {PieChart, Cell, Legend, Pie, Tooltip, ResponsiveContainer} from "recharts";



export default function AnalyticsView() {

  const data = [
    { name: 'Learners', value: 78 },
    { name: 'Educators', value: 22 },
  ];

  return (
    <Box pt={{base: '130px', md: '80px', xl: '80px'}}>
      <SimpleGrid mb='20px' columns={{sm: 2, md: 2, lg: 2}} spacing={{base: '20px', xl: '20px'}}>
        <Card>
          <Stack>
            <Heading>Sessions</Heading>
            <HorizonBarChart
              data={[
                { name: 'Dec', value: 5 },
                { name: 'Jan', value: 15 },
                { name: 'Feb', value: 20 },
                { name: 'Mar', value: 0 },
              ]}
            />
          </Stack>
        </Card>

        <Card>
          <Stack>
            <Heading>Active learners</Heading>
            <HorizonBarChart
              data={[
                { name: 'Dec', value: 25 },
                { name: 'Jan', value: 30 },
                { name: 'Feb', value: 49 },
                { name: 'Mar', value: 49 },
              ]}
            />
          </Stack>
        </Card>
        <Card width={"100%"}>
          <Stack width={"100%"}>
            <Heading>Learners vs Educators</Heading>
            <ResponsiveContainer width="100%" height={300}>
              <PieChart>
                <Pie
                  dataKey="value"
                  isAnimationActive={true}
                  data={data}
                  cx="50%"
                  cy="50%"
                  outerRadius="75%"
                  fill="#8884d8"
                  label
                >
                  {data.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={index === 0 ? '#8884d8' : '#82ca9d'} />
                  ))}
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </Stack>
        </Card>
        {/*<LineAreaChart/>*/}
        {/*<PieChart />*/}
      </SimpleGrid>
    </Box>
  );
}
