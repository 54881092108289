import React, { useState } from "react";
import {
  Text,
  HStack,
  Button,
  Drawer,
  DrawerHeader,
  DrawerContent,
  DrawerBody,
  useDisclosure
} from "@chakra-ui/react";
import OikosButton from "../../../OikosDesignSystem/OikosButton";
import Conversation from "./ConversationMessagesDisplay";
import { useOikos } from "../../../SDK/OikosContext";


const ConversationsDrawer: React.FC<{ conversation: OIKOS.Conversation, educatorName: string, learnerName: string }> = ({ conversation, educatorName, learnerName }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [counterpart, setCounterpart] = useState<OIKOS.user>();
  const [loaded, setLoaded] = useState<boolean>(false);
  const [messageText, setMessageText] = useState<string>("");
  const [conversationMessages, setConversationMessages] = useState<OIKOS.Message[]>([]);

  const { oikos } = useOikos();

  const educatorID = conversation.UniqueName.substring(0, 36);
  const learnerID = conversation.UniqueName.substring(36, 73);

  if (!loaded) {
    oikos.getUser(learnerID)
      .then((user) => {
        setCounterpart(user);
        setLoaded(true);
      });
  }
  //
  // useEffect(() => {
  //
  //
  //
  // }, [loaded, oikos, learnerID]);

  const onConversationClick = () => {
    oikos.getConversationMessages(conversation.UniqueName).then((msgs) => {
      setConversationMessages(msgs);
    });
    onOpen();
  };


  return (
    <>
      <Button onClick={onConversationClick} bg={"green.300"} color={"white"}>
        Conversation
      </Button>

      <Drawer
        isOpen={isOpen}
        placement='right'
        onClose={onClose}
        // size={['full', 'full', 'lg', 'lg', 'lg', 'lg']}
        size={"lg"}
      >
        {loaded && (  <DrawerContent scrollBehavior={"smooth"}>
          <DrawerHeader>
            <HStack>
              {/*<Avatar*/}
              {/*  src={avatarURL}*/}
              {/*  w={"50px"}*/}
              {/*  h={"50px"}*/}
              {/*/>*/}
              <Text>
                {educatorName} | {learnerName}
              </Text>
            </HStack>
          </DrawerHeader>
          <DrawerBody>
            <Conversation
              conversationMessages={conversationMessages}
              oikosUser={counterpart}
            />
          </DrawerBody>
          {/*<DrawerFooter>*/}
          {/*  <Grid templateColumns='repeat(10, 1fr)' gap={4}>*/}
          {/*    <GridItem colSpan={9} h={"75px"}>*/}
          {/*      <Textarea*/}
          {/*        w={"100%"}*/}
          {/*        h={"100%"}*/}
          {/*        value={messageText}*/}
          {/*        onChange={(e) => setMessageText(e.target.value)}*/}
          {/*      />*/}
          {/*    </GridItem>*/}
          {/*    <GridItem colSpan={1} h={"75px"}>*/}
          {/*      <OikosButton*/}
          {/*        buttonText={"Send"}*/}
          {/*        h={"100%"}*/}
          {/*        onClick={counterpart}*/}
          {/*      />*/}
          {/*    </GridItem>*/}
          {/*  </Grid>*/}
          {/*</DrawerFooter>*/}
        </DrawerContent> )}
      </Drawer>

    </>

  );
};

export default ConversationsDrawer;
