import React, {useMemo, useState} from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  List,
  ListItem,
  ListIcon,
  Box,
  Text,
  Checkbox,
  Button,
  VStack
} from '@chakra-ui/react';
import { MdCheckCircle } from 'react-icons/md';
import {OikosAdmin} from "../../../SDK/OikosAdmin";

type RowObj = OIKOS.user; // Assuming OIKOS.user is your user type

type MatchUserDrawerProps = {
  selectedUser: RowObj;
  isOpen: boolean;
  onClose: () => void;
  users: RowObj[];
};

const MatchUserDrawer: React.FC<MatchUserDrawerProps> = ({selectedUser, isOpen, onClose, users }) => {
  const [selectedUsers, setSelectedUsers] = useState<RowObj[]>([]);


  const admin = useMemo(() => new OikosAdmin(), []);

  const handleCheckboxChange = (user: RowObj, isChecked: boolean) => {
    if (isChecked) {
      setSelectedUsers((prev) => [...prev, user]);
    } else {
      setSelectedUsers((prev) => prev.filter((u) => u !== user));
    }
  };

  const handleSave = () => {
    // Implement what should happen with the selected users
    console.log("Selected Users:", selectedUsers);

    for (let i = 0; i < selectedUsers.length; i++) {
      if (selectedUser.UserType === "EDUCATOR") {
        admin.addMatch(selectedUser.ID, selectedUsers[i].ID);
      } else {
        admin.addMatch(selectedUsers[i].ID, selectedUser.ID);
      }
    }
    // Example: You can call a function here with the selected users
    // processSelectedUsers(selectedUsers);
    onClose(); // Close the drawer after saving
  };

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="md">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Match User</DrawerHeader>

        <DrawerBody>
          <VStack align="stretch" spacing={4}>
            <Box overflowY="auto" maxH="70vh">
              <List spacing={3}>
                {users.map((user, index) => (
                  <ListItem key={index} paddingY="2">
                    <Checkbox
                      onChange={(e) => handleCheckboxChange(user, e.target.checked)}
                    >
                      {/*<ListIcon as={MdCheckCircle} color="green.500" />*/}
                      <Text display="inline">{user.Name} - {user.Email} - {user.UserType}</Text>
                      {/* Add more user details if needed */}
                    </Checkbox>
                  </ListItem>
                ))}
              </List>
            </Box>
            <Button colorScheme="blue" onClick={handleSave}>Save</Button>
          </VStack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default MatchUserDrawer;
