// Chakra imports
import { Flex, Image } from '@chakra-ui/react';

// Custom components
import PolisLogo from 'assets/img/Polis_Logo_TM-01.svg';
import { HSeparator } from 'components/separator/Separator';

export function SidebarBrand() {
	//   Chakra color mode

	return (
		<Flex alignItems='center' flexDirection='column'>
			{/*<HorizonLogo h='26px' w='175px' my='32px' color={logoColor} />*/}
			<Image
				src={PolisLogo}
				alt='Polis Logo'
				w={"175px"}
				// boxSize="200px"
			/>
			<HSeparator mb='20px' />
		</Flex>
	);
}

export default SidebarBrand;
