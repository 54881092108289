import {
  Avatar,
  Box,
  Button,
  Drawer,
  Text,
  Grid,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent, DrawerFooter,
  DrawerHeader,
  DrawerOverlay, Flex, Heading,
  SimpleGrid, useDisclosure
} from "@chakra-ui/react";
import Card from "../card/Card";
import TimelineRow from "../dataDisplay/TimelineRow";
import React, {useEffect} from "react";
import {CalendarIcon, CheckIcon, CloseIcon} from "@chakra-ui/icons";
import {OikosAdmin} from "../../SDK/OikosAdmin";
import {useOikos} from "../../SDK/OikosContext";

import adminNewSessionDrawer from "../AdminNewSessionDrawer";
import UserDetailDrawer from "../UserDetailDrawer";

interface SessionDetailsDrawerProps {
  sessionID: string;
  onClose: () => void;
  isOpen: boolean;
}

const SessionParticipantCard = ({ participant }: { participant: OIKOS.user }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Box
      bg={"gray.200"}
      rounded={10}
      onClick={() => {
        onOpen();
      }}
    >
      <UserDetailDrawer
        isOpen={isOpen}
        onClose={onClose}
        selectedUser={participant}/>
    <Grid templateColumns="30% 70%" gap={2} margin={5}>
      <Avatar
        src={`data:image/jpeg;base64,${participant.Avatar}`}
        w={"100px"}
        h={"100px"}
      />
      <Flex direction={"column"} alignItems={"left"} justify={"center"} height={"100%"}>
        {participant.UserType === "EDUCATOR" ?
        <Heading size={"lg"}>Educator</Heading> :
        <Heading size={"lg"}>Learner</Heading>}
        <Heading size={"md"}>{participant.Name} {participant.FamilyName}</Heading>
      </Flex>
    </Grid>
    <Grid templateColumns="30% 70%" gap={2} margin={5}>
        <Text fontWeight={"bold"}>Email</Text>
        <Text>{participant.Email}</Text>
        <Text fontWeight={"bold"}>Timezone</Text>
        <Text>{participant.Profile.Timezone}</Text>
        <Text fontWeight={"bold"}>Bio</Text>
        <Text>{participant.Profile.Bio}</Text>
    </Grid>
  </Box>
  )

}



const SessionDetailsDrawer = ({ sessionID, onClose, isOpen }: SessionDetailsDrawerProps) => {
  const [timelineData, setTimeLineData] = React.useState<any[]>([]);
  const [session, setSession] = React.useState<OIKOS.SessionDetails>(null);

  const admin = new OikosAdmin();
  const { oikos } = useOikos()


  let date = new Date(session?.Session.StartTime);
  let dateString = date.toLocaleString(); // or any other format you prefer


  let footerButtons = (<></>)

  switch (session?.Session.Status) {
    case 1:
      footerButtons = (
        <>
          <Button bg={"green.300"} onClick={() => oikos.sessionAccept(session?.Session.ID)}>
            Accept Session
          </Button>
          <Button bg={"red.300"} onClick={() => oikos.sessionDecline(session?.Session.ID)}>
            Decline Session
          </Button>
        </>
      )
      break;
    case 2:
      footerButtons = (
        <Button bg={"red.300"} onClick={() => oikos.sessionCancelByAdmin(session?.Session.ID)}>
          Cancel Session
        </Button>
      )
      break;
  }


  useEffect(() => {
    admin.getSessionDetails(sessionID).then((session: any) => {
      let tdata: any[] = []
      for (const event of session.Payload.Session.Transactions as OIKOS.TransactionType[]) {
        let title = ""
        let logo = null
        let color = "blue"
        switch (event.DestinationStatus) {
          case 0:
            logo = CalendarIcon
            title = "New Sesssion"
            color = "blue"
            break;
          case 1:
            logo = CalendarIcon
            title = "Session Requested"
            color = "blue"
            break;
          case 2:
            logo = CheckIcon
            title = "Session Accepted"
            color = "green"
            break;
          case 3:
            logo = CloseIcon
            title = "Session Declined"
            color = "red"
            break;
          case 4:
            logo = CalendarIcon
            title = "Session Rescheduled"
            color = "orange"
            break;
          case 5:
            logo = CloseIcon
            title = "Session Cancelled"
            color = "red"
            break;
          default:
            title = "Unknown"
        }

        tdata.push({
          logo: logo,
          title: title,
          date: event.Timestamp,
          color: color
        })
      }

      setTimeLineData(tdata);
      setSession(session.Payload);
    })
  } , [sessionID])

  
return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose} size={"xl"}>
      <DrawerOverlay>
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Session on: {dateString}</DrawerHeader>

          <DrawerBody>
            {session && (


              <>
                <SimpleGrid columns={2} spacing={10}>
                  <SessionParticipantCard participant={session.Educator}/>
                  <SessionParticipantCard participant={session.Learner}/>
                </SimpleGrid>
                <Card maxHeight="100%">

                  <Flex direction="column">
                    {timelineData.map((row, index, arr) => {
                      return (
                        <TimelineRow
                          logo={row.logo}
                          title={row.title}
                          date={row.date}
                          color={row.color}
                          index={index}
                          arrLength={arr.length}
                        />
                      )
                    })}
                  </Flex>

                </Card>


              </>
            )}
          </DrawerBody>
          <DrawerFooter>
            {footerButtons}
          </DrawerFooter>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  )
}

export default SessionDetailsDrawer;
