import React, {useEffect, useMemo, useState} from 'react';
import {Table, Thead, Tbody, Tr, Th, Td, Box, Button} from '@chakra-ui/react';
import {OikosAdmin} from "../../../SDK/OikosAdmin";


interface MatchesTableProps {
  refreshKey: number;
}

const MatchesTable: React.FC<MatchesTableProps> = ({refreshKey}) => {

  const [loaded, setLoaded] = useState(false);
  const [matches, setMatches] = useState<OIKOS.DetailedMatch[]>([]);

  const admin = useMemo(() => new OikosAdmin(), []);

  useEffect(() => {
    admin.listMatches().then(m => {
      setMatches(m);
      console.log(m)
      setLoaded(true);
    })

  }, [loaded, admin, refreshKey]);

  const handleRemove = (match: OIKOS.DetailedMatch) => {
    admin.removeMatch(match.Educator.ID, match.Learner.ID).then(() => {
      setLoaded(false);
    })
  }

  return (
    <Box overflowX="auto">
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Educator</Th>
            <Th>Learner</Th>
            {/*<Th>Matching Date</Th>*/}
            <Th>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {matches.map((match, index) => (
            <Tr key={index}>
              <Td>{match.Educator.Name} {match.Educator.FamilyName}</Td>
              <Td>{match.Learner.Name} {match.Learner.FamilyName}</Td>
              {/*<Td>{match.Timestamp.toDateString()}</Td>*/}
              <Td><Button
                colorScheme="red"
                onClick={() => handleRemove(match)}
              >Remove</Button>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};

export default MatchesTable;
