import React, { useState, useEffect } from 'react';
import {
  Box,
  SimpleGrid,
  useDisclosure,
} from "@chakra-ui/react";
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { OikosAdmin } from "../../../SDK/OikosAdmin";
import SessionDetailsDrawer from "../../../components/session/SessionDetailsDrawer";

const localizer = momentLocalizer(moment);

export default function CalendarView() {
  const [loaded, setLoaded] = useState(false);
  const [sessions, setSessions] = useState([]);
  const {isOpen, onOpen, onClose} = useDisclosure();
  const [selectedSessionID, setSelectedSessionID] = useState<string>(null);

  const admin = new OikosAdmin();

  const [dateRange, setDateRange] = useState({
    start: moment().startOf('month').toISOString(),
    end: moment().endOf('month').toISOString(),
  });

  useEffect(() => {
    const loadSessions = async () => {
      console.log(dateRange.start)
      console.log(dateRange.end)
      const sessions = await admin.getCalendar(dateRange.start, dateRange.end);
      if (sessions.Payload) {
        const formattedSessions = sessions.Payload.map((session: any) => ({
          ...session,
          start: moment(session.start).toDate(),
          end: moment(session.end).toDate(),
        }));
        setSessions(formattedSessions);
      } else {
        console.error('sessions.Payload is null or undefined');
        setSessions([]);
      }
      setLoaded(true);
    };

    loadSessions();
  }, [dateRange]);

  const handleRangeChange = (dates: Date[] | { start: Date; end: Date }) => {
    if (Array.isArray(dates)) {
      setDateRange({
        start: moment(dates[0]).startOf('day').toISOString(),
        end: moment(dates[dates.length - 1]).endOf('day').toISOString(),
      });
    } else {
      setDateRange({
        start: moment(dates.start).startOf('day').toISOString(),
        end: moment(dates.end).endOf('day').toISOString(),
      });
    }
  };

  const eventStyleGetter = (event: any, start: any, end: any, isSelected: boolean) => {
    let backgroundColor;
    switch (event.Status) {
      case 0:
        backgroundColor = 'blue';
        break;
      case 1:
        backgroundColor = 'blue';
        break;
      case 2:
        backgroundColor = 'green';
        break;
      case 3:
        backgroundColor = 'red';
        break;
      case 4:
        backgroundColor = 'orange';
        break;
      case 5:
        backgroundColor = 'red';
        break;
      default:
        backgroundColor = 'blue';
    }
    let style = {
      backgroundColor: backgroundColor,
      borderRadius: '0px',
      opacity: 0.8,
      color: 'white',
      border: '0px',
      display: 'block',
    };
    return { style };
  };

  const onSelectEvent = (event: any) => {
    setSelectedSessionID(event.SessionID);
    onOpen();
  };

  return (
    <Box pt={{base: '130px', md: '80px', xl: '80px'}}>
      {selectedSessionID && <SessionDetailsDrawer
        isOpen={isOpen}
        onClose={onClose}
        sessionID={selectedSessionID}
      />}
      <SimpleGrid mb='20px' columns={{sm: 1, md: 1}} spacing={{base: '20px', xl: '20px'}}>
        <Calendar
          localizer={localizer}
          events={sessions}
          startAccessor="start"
          endAccessor="end"
          selectable
          popup={true}
          onSelectEvent={onSelectEvent}
          eventPropGetter={eventStyleGetter}
          style={{
            minHeight: 500,
            height: "80vh",
          }}
          onRangeChange={handleRangeChange}
        />
      </SimpleGrid>
    </Box>
  );
}
