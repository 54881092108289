import { useAuth } from "./Hooks/useAuth";
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";
import AuthLayout from "./layouts/auth";
import AdminLayout from "./layouts/admin";
import React from "react";

export const App = () => {
  const { isLoading, isAuthenticated } = useAuth();

  if (isLoading) {
    return (<></>); // Display a loading indicator while checking auth state
  }

  if (isAuthenticated) {
    return (
      <BrowserRouter>
        <Switch>
          <Route path={`/admin`} component={AdminLayout} />
          <Redirect from='/' to='/admin' />
        </Switch>
      </BrowserRouter>
    );
  } else {
    return (
      <BrowserRouter>
        <Switch>
          <Route path={`/auth`} component={AuthLayout} />
          <Redirect from='/' to='/auth' />
        </Switch>
      </BrowserRouter>
    );
  }
};
