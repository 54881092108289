import React, { useEffect, useState, useMemo } from 'react';
import {
  Box,
  Button,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Badge,
  Input,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useDisclosure,
} from '@chakra-ui/react';
import { getCoreRowModel, getSortedRowModel, SortingState, useReactTable } from '@tanstack/react-table';
import Card from 'components/card/Card';
import SessionDetailsDrawer from 'components/session/SessionDetailsDrawer';
import { OikosAdmin } from 'SDK/OikosAdmin';
import { ChevronDownIcon, HamburgerIcon } from '@chakra-ui/icons';


type RowObj = OIKOS.SessionDetails; // Make sure OIKOS.user is a type defined elsewhere

const SessionsTable: React.FC = () => {
  const [sorting, setSorting] = useState<SortingState>([]);
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
  const [loaded, setLoaded] = useState(false);
  const [tableData, setTableData] = useState<RowObj[]>([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedSession, setSelectedSession] = useState<RowObj | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>('');

  const admin = useMemo(() => new OikosAdmin(), []);


  // const handlePasswordDrawerOpen = (user: RowObj) => {
  //   setSelectedUser(user);
  //   onPwdDrawerOpen();
  // };
  //
  // const handleMatchUserDrawerOpen = () => {
  //   onMatchUserDrawerOpen();
  // };

  useEffect(() => {
    if (!loaded) {
      admin.listSessions()
        .then((sessions: OIKOS.SessionDetails[]) => {
          console.log(sessions)
          setTableData(sessions);
          setLoaded(true);
        })
        .catch(console.log);
    }
  }, [loaded, admin]);

  // const filteredData = useMemo(
  //   () => tableData.filter(
  //     (user) =>
  //       user.Name.toLowerCase().includes(searchTerm.toLowerCase()) ||
  //       user.FamilyName.toLowerCase().includes(searchTerm.toLowerCase()) ||
  //       user.Email.toLowerCase().includes(searchTerm.toLowerCase())
  //   ),
  //   [searchTerm, tableData]
  // );

  const columns = useMemo(
    () => [
      {
        id: 'educator',
        header: 'EDUCATOR',
        cell: (info: { row: { original: RowObj }}) => <Text color={textColor} fontSize='sm' fontWeight='700'>{info.row.original.Educator.Name} {info.row.original.Educator.FamilyName}</Text>
      },
      {
        id: 'learner',
        header: 'LEARNER',
        cell: (info: { row: { original: RowObj }}) => <Text color={textColor} fontSize='sm' fontWeight='700'>{info.row.original.Learner.Name} {info.row.original.Learner.FamilyName}</Text>
      },
      {
        id: 'date',
        header: 'DATE',
        cell: (info: { row: { original: RowObj }}) => {
          let date = new Date(info.row.original.Session.StartTime);
          let dateString = date.toLocaleString(); // or any other format you prefer
          return <Text color={textColor} fontSize='sm' fontWeight='700'>{dateString}</Text>
        }
      },
      {
        id: 'status',
        header: 'STATUS',
        cell: (info: { row: { original: RowObj }}) => {
          switch (info.row.original.Session.Status) {
            case 0:
              return <Badge colorScheme='green'>NEW</Badge>
            case 1:
              return <Badge colorScheme='blue'>REQUESTED</Badge>
            case 2:
              return <Badge colorScheme='green'>ACCEPTED</Badge>
            case 3:
              return <Badge colorScheme='orange'>DECLINED</Badge>
            case 4:
              return <Badge colorScheme='yellow'>RESCHEDULED</Badge>
            case 5:
              return <Badge colorScheme='red'>CANCELLED</Badge>
          }
        }
      }
    ],
    [textColor]
  );

  const table = useReactTable({
    // data: filteredData,
    data: tableData,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });


  return (
    <Card flexDirection='column' w='100%' px='0px' overflowX={{ sm: 'scroll', lg: 'hidden' }}>
      <Flex px='25px' mb="8px" justifyContent='space-between' align='center'>
        <Input
          placeholder="Search by name, family name or email"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </Flex>

      {selectedSession && <SessionDetailsDrawer
        isOpen = {isOpen}
        onClose = {onClose}
        sessionID = {selectedSession.Session.ID}
      />}
      <Box>
        <Table variant='simple' color='gray.500' mb='24px' mt="12px">
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <Th
                      key={header.id}
                      colSpan={header.colSpan}
                      pe='10px'
                      borderColor={borderColor}
                      cursor='pointer'
                      onClick={header.column.getToggleSortingHandler()}>
                      {header.column.columnDef.header as React.ReactNode}
                    </Th>
                  );
                })}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {table.getRowModel().rows.slice(0, tableData.length).map((row) => {
              return (
                <Tr key={row.id}
                    _hover={{ bg: "gray.600", color: "gray.100" }}
                    onClick={() => {
                        setSelectedSession(row.original);
                        onOpen();
                    }}
                >
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <Td
                        key={cell.id}
                        fontSize={{ sm: '14px' }}
                        minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                        borderColor='transparent'>
                        {(cell.column.columnDef.cell as (cell: any) => React.ReactNode)(cell)}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </Box>
    </Card>
  );
}

export default SessionsTable;
