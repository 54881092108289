// TimeSlotSelector.tsx
import React, {useState, useEffect} from 'react';
import moment from 'moment-timezone';
import {
  Button,
  Box,
  HStack,
  Text,
  VStack,
  Grid,
  GridItem,
  Flex,
  SimpleGrid,
  useToast,
} from '@chakra-ui/react';
import Card from "./card/Card";
import {
  ArrowLeftIcon,
  ArrowRightIcon
} from "@chakra-ui/icons"

import {useOikos} from '../SDK/OikosContext';

export interface TimeSlot {
  start: string;
  end: string;
}

export interface DayTimeSlots {
  Day: string;
  // slots: TimeSlot[];
  Slots: string[];
}


interface TimeSlotSelectorProps {
  educator: OIKOS.user;
  learner: OIKOS.user;
}


const TimeSlotSelector: React.FC<TimeSlotSelectorProps> = ({educator, learner}) => {
  const [dayTimeSlots, setDayTimeSlots] = useState<DayTimeSlots[]>([]);

  const minimumOffset = 2;
  const [startDateOffset, setStartDateOffset] = useState<number>(minimumOffset);

  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);

  const toast = useToast();

  const decreaseStartDateOffset = () => {
    let offset = startDateOffset - 1;
    if (offset < minimumOffset) {
      offset = minimumOffset;
    }
    setStartDateOffset(offset);
    fetchAndSetTimeSlots();
  }

  const increaseStartDateOffset = () => {
    setStartDateOffset(startDateOffset + 1);
    fetchAndSetTimeSlots();
  }

  const displayDate = (date: string) => {
    const res = moment(date).tz(educator.Profile.Timezone).format('YYYY-MM-DD HH:mm');
    return res;
  }

  const getStartDate = () => {
    return moment().add(startDateOffset, 'days').format('YYYY-MM-DD');
  }

  const {oikos} = useOikos();

  const fetchAndSetTimeSlots = async () => {
    try {
      const timeSlots = await oikos.getEducatorAvailability(educator.ID, getStartDate(), educator.Profile.Timezone)
      setDayTimeSlots(timeSlots);
    } catch (error) {
      console.error('Error fetching time slots:', error);
    }
  };


  useEffect(() => {
    fetchAndSetTimeSlots();
  }, []);

  const handleSelectTimeSlot = (timeSlot: TimeSlot) => {
    timeSlot.end = moment(timeSlot.start).add(30, 'minutes').format('YYYY-MM-DDTHH:mm:ss')
    setSelectedTimeSlot(timeSlot);
  }

  const handleCreateSession = () => {
    console.log("Create Session")
    oikos.sessionRequest(learner.ID, educator.ID, selectedTimeSlot.start, 30).then((res: any) => {
      oikos.sendMessage(learner.ID, educator.ID, "Session Requested initiated by Administrator")
        .then((res: any) => {
          toast({
            title: "Session Requested",
            description: "Session Requested initiated by Administrator",
            status: "success",
            duration: 9000,
            isClosable: true,
          })
          setSelectedTimeSlot(null);
        })
        .catch((err: any) => {
          toast({
            title: "Error",
            description: "Error sending message",
            status: "error",
            duration: 9000,
            isClosable: true,
          })
        })
    }).catch((err: any) => {
      toast({
        title: "Error",
        description: "Error creating session",
        status: "error",
        duration: 9000,
        isClosable: true,
      })

    })
  }


  return (
    <Flex
      // height={"626px"}
      width={"100%"}
      rounded={"20px"}
      background={"neutral-100"}
      shadow={"lg"}
      mt={"20px"}

    >
      {!selectedTimeSlot && <Grid templateColumns={"repeat(42,1fr)"} w={"100%"} h={"100%"} gap={"4px"}>
        <GridItem colSpan={1}>
          <Button
            minW={0}
            maxW={"10px"}
            height={"100%"}
            onClick={decreaseStartDateOffset}
            leftIcon={<ArrowLeftIcon/>}
          />
        </GridItem>
        {dayTimeSlots.map((dayTimeSlot, dayIndex) => {
          const date = moment(dayTimeSlot.Day)
          const dayOfWeek = date.format('ddd')
          const dayOfMonth = date.format('DD')

          return (
            <GridItem colSpan={8} height={"100%"}>
              <VStack key={dayTimeSlot.Day} height={"100%"} align="center" spacing={1} bg={"gray.200"} py={"5px"}>

                <VStack align={"left"} spacing={"-10px"}>
                  <Text fontFamily={"lato"} fontSize="18" fontWeight="medium">
                    {dayOfWeek}
                  </Text>
                  <Text fontFamily={"lato"} fontSize="32" fontWeight="bold">
                    {dayOfMonth}
                  </Text>
                </VStack>

                {dayTimeSlot.Slots.map((slot, slotIndex) => {

                    const date = moment(slot).tz(educator.Profile.Timezone)
                    const time = date.format("h:mm")
                    const ampm = date.format("a")

                    return (
                      <>
                        <Box
                          cursor="pointer"
                          borderWidth="1px"
                          _checked={{
                            bg: 'teal.600',
                            color: 'white',
                            borderColor: 'teal.200',
                          }}
                          _focus={{
                            boxShadow: 'outline',
                          }}
                          width={"100%"}
                          maxW={"100%"}
                          px={"5px"}
                          py={"2px"}
                          bg={"gray.400"}
                          rounded={"3px"}
                          onClick={() => handleSelectTimeSlot({start: slot, end: slot})}
                        >
                          <HStack spacing={"2px"}>
                            <Text fontWeight={"extrabold"} fontSize={14}>{time}</Text>
                            {/*<Box height={"1px"} width={"100%"} bg={"neutral-600"}/>*/}
                            <Text fontWeight={"light"} fontSize={10}>{ampm}</Text>
                          </HStack>
                        </Box>
                      </>
                    )
                  }
                )}
              </VStack>
            </GridItem>
          )
        })}
        <GridItem colSpan={1}>
          <Button
            minW={0}
            maxW={"10px"}
            height={"100%"}
            onClick={increaseStartDateOffset}
            rightIcon={<ArrowRightIcon/>}
          />
        </GridItem>
      </Grid> }
      {selectedTimeSlot &&
        <Card  bg={"gray.600"} shadow={"lg"}>
        <SimpleGrid fontSize={20} color={"gray.100"} columns={2} gap={4}>
          <Text fontWeight={"bold"}>Start Time:</Text>
          <Text fontWeight={"light"}>{displayDate(selectedTimeSlot.start)}</Text>
          <Text fontWeight={"bold"}>End Time:</Text>
          <Text fontWeight={"light"}>{displayDate(selectedTimeSlot.end)}</Text>
          <Button
            bg={"blue.500"}
            color={"white"}
            shadow={"lg"}
            onClick={handleCreateSession}
          >Create Session</Button>
          <Button
            bg={"red.500"}
            color={"white"}
            shadow={"lg"}
            onClick={() => setSelectedTimeSlot(null)}
          >Cancel</Button>
        </SimpleGrid>


      </Card>
      }
    </Flex>
  );

};

export default TimeSlotSelector;
