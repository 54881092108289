

// Chakra imports
import { Box, SimpleGrid, Button } from '@chakra-ui/react';
import UsersTable from 'views/admin/users/UsersTable';
import AddUserDrawer from "components/AddUserDrawer";
import { useState } from 'react';

export default function Settings() {
	// Chakra Color Mode
	const [isDrawerOpen, setDrawerOpen] = useState(false);
	const [refreshKey, setRefreshKey] = useState(0);

	const refreshData = () => {
		const newKey = refreshKey + 1;
		setRefreshKey(newKey);
	}

	const handleDrawerOpen = () => {
		setDrawerOpen(true);
	};

	const handleDrawerClose = () => {
		setDrawerOpen(false);
	};


	return (
		<Box pt={{ base: '130px', md: '80px', xl: '80px' }}>

			<AddUserDrawer isOpen={isDrawerOpen} onClose={handleDrawerClose} refresh={refreshData}/>
			<SimpleGrid mb='20px' columns={{ sm: 1, md: 1 }} spacing={{ base: '20px', xl: '20px' }}>
				<Button onClick={handleDrawerOpen}>Add User</Button>
				<UsersTable refreshKey={refreshKey}/>
			</SimpleGrid>
		</Box>
	);
}
