import React, {useState} from 'react';
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
} from '@chakra-ui/react';
import TimeSlotSelector from "./TimeSlotSelector";
import {UserSelector} from "./UserSelector";


interface AdminNewSessionDrawerProps {
  isOpen: boolean;
  onClose: () => void;
}

export const AdminNewSessionDrawer: React.FC<AdminNewSessionDrawerProps> = ({isOpen, onClose}) => {
  const [selectedUsers, setSelectedUsers] = useState({learner: null as OIKOS.user | null, educator: null as OIKOS.user | null});

  const handleSelectUser = (type: string, user: OIKOS.user | null) => setSelectedUsers({...selectedUsers, [type]: user});

  return (
    <Drawer isOpen={isOpen} onClose={onClose} size={"lg"}>
      <DrawerOverlay>
        <DrawerContent>
          <DrawerHeader>Create New Session</DrawerHeader>
          <DrawerBody>
            <UserSelector type="learner" selectedUser={selectedUsers.learner} handleSelectUser={handleSelectUser} />
            {selectedUsers.learner &&
              <UserSelector type="educator" selectedUser={selectedUsers.educator} handleSelectUser={handleSelectUser} />
            }
            {
              selectedUsers.learner && selectedUsers.educator &&
              <TimeSlotSelector
                educator={selectedUsers.educator}
                learner={selectedUsers.learner}
              />
            }
          </DrawerBody>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
};

export default AdminNewSessionDrawer;
