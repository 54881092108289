import { Icon } from '@chakra-ui/react';
import { MdBarChart, MdPerson, MdLock, MdCalendarMonth } from 'react-icons/md';
import { BiSolidConversation } from "react-icons/bi";
import { BsPersonVideo2 } from "react-icons/bs";
import { TbDeviceDesktopAnalytics } from "react-icons/tb";
import Users from 'views/admin/users';
import Conversations from 'views/admin/conversations';
import Matches from 'views/admin/matches';
import CalendarView from 'views/admin/calendar';
import Sessions from 'views/admin/sessions';
import AnalyticsView from 'views/admin/analytics';

// Auth Imports
import SignInCentered from 'views/auth/signIn';

const routes = [
	// {
	// 	name: 'Main Dashboard',
	// 	layout: '/admin',
	// 	path: '/default',
	// 	icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
	// 	component: MainDashboard
	// },
	{
		name: 'Sign In',
		layout: '/auth',
		path: '/sign-in',
		icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
		component: SignInCentered
	},
	{
		name: 'Users',
		layout: '/admin',
		icon: <Icon as={MdBarChart} width='20px' height='20px' color='inherit' />,
		path: '/users',
		component: Users
	},
	{
		name: 'Conversations',
		layout: '/admin',
		path: '/conversations',
		icon: <Icon as={BiSolidConversation} width='20px' height='20px' color='inherit' />,
		component: Conversations
	},
	{
		name: 'Matches',
		layout: '/admin',
		path: '/Matches',
		icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
		component: Matches
	},
	{
		name: 'Sessions',
		layout: '/admin',
		path: '/Sessions',
		icon: <Icon as={BsPersonVideo2} width='20px' height='20px' color='inherit' />,
		component: Sessions
	},
	{
		name: 'Calendar',
		layout: '/admin',
		path: '/Calendar',
		icon: <Icon as={MdCalendarMonth} width='20px' height='20px' color='inherit' />,
		component: CalendarView
	},
	{
		name: 'Analytics',
		layout: '/admin',
		path: '/Analytics',
		icon: <Icon as={TbDeviceDesktopAnalytics} width='20px' height='20px' color='inherit' />,
		component: AnalyticsView
	}
];

export default routes;
