import React, { useEffect, useState, useMemo } from 'react';
import {
  Box,
  Button,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Badge,
  Input,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useDisclosure, Avatar,
} from '@chakra-ui/react';
import { getCoreRowModel, getSortedRowModel, SortingState, useReactTable } from '@tanstack/react-table';
import Card from 'components/card/Card';
import UserDetailDrawer from 'components/UserDetailDrawer';
import { OikosAdmin } from 'SDK/OikosAdmin';
import { ChevronDownIcon, HamburgerIcon } from '@chakra-ui/icons';
import ChangePasswordDrawer from './ChangePasswordDrawer';
import MatchUserDrawer from "../matches/MatchUsersDrawer";


type RowObj = OIKOS.user; // Make sure OIKOS.user is a type defined elsewhere

interface UsersTableProps {
  refreshKey: number;
}

const UsersTable: React.FC<UsersTableProps> = ({refreshKey}) => {
  const [sorting, setSorting] = useState<SortingState>([]);
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
  const [loaded, setLoaded] = useState(false);
  const [tableData, setTableData] = useState<RowObj[]>([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedUser, setSelectedUser] = useState<RowObj | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const { isOpen: isPwdDrawerOpen, onOpen: onPwdDrawerOpen, onClose: onPwdDrawerClose } = useDisclosure();
  const { isOpen: isMatchUserDrawerOpen, onOpen: onMatchUserDrawerOpen, onClose: onMatchUserDrawerClose } = useDisclosure();

  const admin = useMemo(() => new OikosAdmin(), []);


  const fitlerEducators = (users: RowObj[]) => {
    return users.filter((user) => user.UserType === 'EDUCATOR');
  }

  const fitlerLearners = (users: RowObj[]) => {
    return users.filter((user) => user.UserType !== 'EDUCATOR');
  }

  const handlePasswordDrawerOpen = (user: RowObj) => {
    setSelectedUser(user);
    onPwdDrawerOpen();
  };

  const handleMatchUserDrawerOpen = () => {
    onMatchUserDrawerOpen();
  };

  useEffect(() => {

      admin.listUsers()
        .then((users: OIKOS.user[]) => {
          setTableData(users);
          setLoaded(true);
        })
        .catch(console.log);

  }, [loaded, admin, refreshKey]);

  const filteredData = useMemo(
    () => tableData.filter(
      (user) =>
        user.Name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.FamilyName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.Email.toLowerCase().includes(searchTerm.toLowerCase())
    ),
    [searchTerm, tableData]
  );

  const columns = useMemo(
    () => [
    {
      id: 'avatar',
      header: '',
      cell: (info: { row: { original: RowObj }}) => <Avatar src={`data:image/jpeg;base64,${info.row.original.Avatar}`} />
    },
    {
      id: 'name',
      header: 'NAME',
      cell: (info: { row: { original: RowObj }}) => <Text color={textColor} fontSize='sm' fontWeight='700'>{info.row.original.Name}</Text>
    },
    {
      id: 'familyName',
      header: 'FAMILY NAME',
      cell: (info: { row: { original: RowObj }}) => <Text color={textColor} fontSize='sm' fontWeight='700'>{info.row.original.FamilyName}</Text>
    },
    {
      id: 'email',
      header: 'EMAIL',
      cell: (info: { row: { original: RowObj }}) => <Text color={textColor} fontSize='sm' fontWeight='700'>{info.row.original.Email}</Text>
    },
    {
      id: 'userType',
      header: 'USER TYPE',
      cell: (info: { row: { original: RowObj }}) => (
        <Badge
          colorScheme={
            info.row.original.UserType === 'EDUCATOR' ? 'purple'
              : info.row.original.UserType === 'LEARNER' ? 'green'
                : 'blue'
          }>
          {info.row.original.UserType}
        </Badge>
      )
    },
    {
      id: 'status',
      header: 'STATUS',
      cell: (info: { row: { original: RowObj }}) => (
        <Badge
          colorScheme={
            info.row.original.Status === 'ACTIVE' ? 'green'
              : info.row.original.Status === 'INACTIVE' ? 'red'
                : 'blue'
          }>
          {info.row.original.Status}
        </Badge>
      )
    },
    {
      id: 'actions',
      header: 'ACTIONS',
      cell: (info: { row: { original: RowObj }}) => (
        <Menu>
          <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
            <HamburgerIcon />
          </MenuButton>
          <MenuList>
            <MenuItem onClick={() => {
              setSelectedUser(info.row.original);
              handlePasswordDrawerOpen(info.row.original);
            }}>
              Change Password
            </MenuItem>
            <MenuItem onClick={() => {
              setSelectedUser(info.row.original);
              onOpen();
            }}>
              Edit User
            </MenuItem>
            <MenuItem onClick={() => {
              setSelectedUser(info.row.original);
              handleMatchUserDrawerOpen();
            }}>
              Match User
            </MenuItem>
            {info.row.original.Status === "INACTIVE" ?
              <MenuItem onClick={() => {
                admin.enableUser(info.row.original.ID).then(() => {
                  info.row.original.Status = "ACTIVE";
                  setSelectedUser(info.row.original)
                })
              }}>
                Enable User
              </MenuItem>
              :
              <MenuItem onClick={() => {
                admin.disableUser(info.row.original.ID).then(() => {
                  console.log(info.row.original.ID)
                  info.row.original.Status = "INACTIVE";
                  setSelectedUser(info.row.original)
                })

              }}>
                Disable User
              </MenuItem>
            }
          </MenuList>
        </Menu>
      )
    }
  ],
    [textColor, onMatchUserDrawerOpen, handleMatchUserDrawerOpen, handlePasswordDrawerOpen]
  );

  const table = useReactTable({
    data: filteredData,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });


  return (
    <Card flexDirection='column' w='100%' px='0px' overflowX={{ sm: 'scroll', lg: 'hidden' }}>
      <Flex px='25px' mb="8px" justifyContent='space-between' align='center'>
        {/*<Text color={textColor} fontSize='22px' fontWeight='700' lineHeight='100%'>*/}
        {/*  Users Table*/}
        {/*</Text>*/}
        {/*<Menu />*/}
        <Input
          placeholder="Search by name, family name or email"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </Flex>
      <Box>
        <Table variant='simple' color='gray.500' mb='24px' mt="12px">
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <Th
                      key={header.id}
                      colSpan={header.colSpan}
                      pe='10px'
                      borderColor={borderColor}
                      cursor='pointer'
                      onClick={header.column.getToggleSortingHandler()}>
                      {header.column.columnDef.header as React.ReactNode}
                    </Th>
                  );
                })}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {table.getRowModel().rows.slice(0, filteredData.length).map((row) => {
              return (
                <Tr key={row.id} >
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <Td
                        key={cell.id}
                        fontSize={{ sm: '14px' }}
                        minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                        borderColor='transparent'>
                        {(cell.column.columnDef.cell as (cell: any) => React.ReactNode)(cell)}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </Box>
      <UserDetailDrawer isOpen={isOpen} onClose={onClose} selectedUser={selectedUser} />
      {selectedUser &&
        <>
          <ChangePasswordDrawer email={selectedUser.Email} isOpen={isPwdDrawerOpen} onClose={onPwdDrawerClose} />
          <MatchUserDrawer
            selectedUser={selectedUser}
            isOpen={isMatchUserDrawerOpen}
            onClose={onMatchUserDrawerClose}
            users={selectedUser.UserType === 'EDUCATOR' ? fitlerLearners(filteredData) : fitlerEducators(filteredData)}
          />
        </>
      }
    </Card>
  );
}

export default UsersTable;
