import axios from "axios";
import {useToast} from "@chakra-ui/react"; // Simplified import

export class OikosAdmin {
    readonly serviceEndPoint: string = process.env.REACT_APP_API_URL || "http://localhost:8090";
    readonly OIKOS_API_KEY: string = process.env.REACT_APP_OIKOS_API_KEY || "<API_KEY_NOT_SET>";

    // toast = useToast();

    async Get(url: string, params: any): Promise<any> { // Added return type for type checking
        const { data } = await axios.get(this.serviceEndPoint + url, {
            headers: {
                Authorization: `Bearer ${this.OIKOS_API_KEY}`,
            },
            params: params,
        });

        return new Promise((resolve, reject) => {
            if (data.Error) {
                reject(new Error(data.Payload));
            } else {
                resolve(data.Payload);
            }
        });
    }

    async Post(url: string, postData: any): Promise<any> { // Added return type for type checking
        const config = {
            headers: {
                Authorization: `Bearer ${this.OIKOS_API_KEY}`,
            },
        };
        const { data } = await axios.post(this.serviceEndPoint + url, postData, config);
        return data;
    }

    // Converted listUsers to an instance method to be able to use this.Get
    async listUsers(): Promise<OIKOS.user[]> { // Added return type for type checking
        const emtpyUsers: OIKOS.user[] = [];

        const users  =  await this.Get("/admin/users", {});
        if (users === null) {
            return emtpyUsers;
        }
        return users;
    }

    async newUser(
      name: string,
      familyName: string,
      email: string,
      password: string,
      userType: string
      ): Promise<any> { // Added return type for type checking
        const user = {
            Name: name,
            FamilyName: familyName,
            Email: email,
            Password: password,
            UserType: userType,
        }
        await this.Post("/users/new", user);
    }


    async listEducators(): Promise<any> { // Added return type for type checking
        return this.Get("/admin/educators", {});
    }

    async listLearners(): Promise<any> { // Added return type for type checking
        return this.Get("/admin/learners", {});
    }

    async listSessions(): Promise<any> { // Added return type for type checking
        const sessions = await this.Get("/admin/sessions", {});
        return sessions;
    }

    async listConversations(): Promise<any> { // Added return type for type checking
        return this.Get("/admin/conversations", {});
    }


    async getSessionDetails(SessionID: string): Promise<OIKOS.SessionDetails> {
        return this.Post("/admin/session/get", {SessionID: SessionID});
    }

    async changeUserPassword(username: string, password: string): Promise<any> { // Added return type for type checking
        return this.Post("/admin/user/password/set", {
            UserID: username,
            Password: password
        });
    }

    async getConversationRecordings(UniqueName: string): Promise<any> {
        return this.Post("/admin/video/recordings/list", {
            UniqueName: UniqueName
        });
    }

    async fetchRecording(RecordingSid: string): Promise<void> {
        try {
            // Make a request to the server endpoint
            const response = await fetch(this.serviceEndPoint + '/admin/video/composition/get', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer YourAuthToken' // Add your auth token or credentials here if needed
                },
                body: JSON.stringify({
                    CompositionSid: RecordingSid
                })
            });

            // Check if the response is ok
            if (!response.ok) {
                throw new Error(`Server responded with status: ${response.status}`);
            }

            // Assuming the response is streamed and the server sets the correct content type
            const blob = await response.blob();

            // Create a URL for the blob
            const blobUrl = window.URL.createObjectURL(blob);

            // Create a temporary anchor element and trigger a download
            const anchor = document.createElement('a');
            anchor.href = blobUrl;
            anchor.download = `${RecordingSid}.mp4`; // You can specify the desired file name
            document.body.appendChild(anchor);
            anchor.click();

            // Clean up
            window.URL.revokeObjectURL(blobUrl);
            document.body.removeChild(anchor);

        } catch (error) {
            console.error('Error fetching the recording:', error);
            // Here, you'd handle the error, maybe update the state to show an error message to the user
        }
    }


    async addMatch (educatorID: string, learnerID: string): Promise<any> {
        const match = {
            EducatorID: educatorID,
            LearnerID: learnerID,
        }
        return this.Post("/admin/matching/new", match);
    }

    async removeMatch (educatorID: string, learnerID: string): Promise<any> {
        const match = {
            EducatorID: educatorID,
            LearnerID: learnerID,
        }
        return this.Post("/admin/matching/remove", match);
    }

    async listMatches(): Promise<any> {
        return this.Get("/admin/matching/list", {});
    }

    disableUser(UserID: string) {
        return this.Post("/users/disable", {
            UserID: UserID
        }).then((data) => {
            return new Promise((resolve, reject) => {
            if (data.Error) {
                reject(new Error(data.Payload))
            } else {
                resolve(data.Payload)
            }
        })
    });
    }

    enableUser(UserID: string) {
        return this.Post("/users/enable", {
            UserID: UserID
        }).then((data) => {
            return new Promise((resolve, reject) => {
            if (data.Error) {
                reject(new Error(data.Payload))
            } else {
                resolve(data.Payload)
            }
        })
    });
    }

    async getCalendar(startDate: string, endDate: string): Promise<any> {

        return this.Post("/admin/session/calendar",
          {StartDate: startDate, EndDate: endDate});
    }

}
