import React, {  useState } from "react";
import {
  HStack,
  Button,
  Drawer,
  DrawerHeader,
  DrawerContent,
  DrawerBody,
  useDisclosure,
} from "@chakra-ui/react";
import { OikosAdmin } from "SDK/OikosAdmin";
import VideoPlayer from "../../../components/VideoPlayer";


const RecordingsDrawer: React.FC<{ UniqueName: string }> = ({ UniqueName }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loaded, setLoaded] = useState<boolean>(false);
  const [recodingsList, setRecordingsList] = useState<string[]>([]);

  const admin = new OikosAdmin();





  const onClick = () => {
    if (!loaded) {
      admin.getConversationRecordings(UniqueName)
        .then((recordings: any) => {
          console.log(recordings.Payload)
          setRecordingsList(recordings.Payload)
          setLoaded(true);
          onOpen();
        });
    } else {
      onOpen();
    }
  }



  return (
    <>

      <Button onClick={onClick} bg={"blue.300"} color={"white"}>
        Recordings
      </Button>


      <Drawer
        isOpen={isOpen}
        placement='right'
        onClose={onClose}
        // size={['full', 'full', 'lg', 'lg', 'lg', 'lg']}
        size={"lg"}
      >
        {loaded && (  <DrawerContent scrollBehavior={"smooth"}>
          <DrawerHeader>
            Session Recordings
          </DrawerHeader>
          <DrawerBody>
            {
              recodingsList.map((recording) => {
                if (recording === "") {
                  return <></>
                }
                return (
                  <HStack>
                    <VideoPlayer  recordingSid={recording}/>
                    {/*<Text>{recording}</Text>*/}
                    {/*<button onClick={() => admin.fetchRecording(recording)}>Download</button>*/}
                  </HStack>
                )
              })
            }
          </DrawerBody>

        </DrawerContent> )}
      </Drawer>

    </>

  );
};

export default RecordingsDrawer;
