import React from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  FormControl,
  FormLabel,
  Input,
  Grid,
  GridItem,
  Avatar,
  Textarea,
} from '@chakra-ui/react';
import Card from "./card/Card";

type UserDetailDrawerProps = {
  isOpen: boolean;
  onClose: () => void;
  selectedUser: OIKOS.user | null; // Make sure OIKOS.user is defined elsewhere
};

const UserDetailDrawer: React.FC<UserDetailDrawerProps> = ({ isOpen, onClose, selectedUser }) => {
  return (
    <Drawer isOpen={isOpen} onClose={onClose} size="xl">
      <DrawerOverlay>
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>User Details</DrawerHeader>

          <Card bg={"gray.600"} m={"20px"} >
          <DrawerBody>
            {selectedUser && (
              <>
              <Grid templateColumns="repeat(3, 1fr)" gap={0}>
                {/* Basic Information */}
                <GridItem colSpan={1}>
                  <Avatar
                    w={"250px"}
                    h={"250px"}
                    name={`${selectedUser.Name} ${selectedUser.FamilyName}`}
                    src={`data:image/jpeg;base64,${selectedUser.Avatar}`}
                  />
                </GridItem>

                <GridItem colSpan={2}>
                  <FormControl mb={10}>
                    <FormLabel color={"gray.100"}>Name</FormLabel>
                    <Input bg={"gray.100"} value={selectedUser.Name} />
                  </FormControl>
                  <FormControl mb={10}>
                    <FormLabel color={"gray.100"}>Family Name</FormLabel>
                    <Input bg={"gray.100"} value={selectedUser.FamilyName}/>
                  </FormControl>
                  <FormControl mb={10}>
                    <FormLabel color={"gray.100"}>Email</FormLabel>
                    <Input bg={"gray.100"} value={selectedUser.Email}/>
                  </FormControl>
                  {/*<FormControl mb={10}>*/}
                  {/*  <FormLabel>Display Name</FormLabel>*/}
                  {/*  <Input value={selectedUser.DisplayName}/>*/}
                  {/*</FormControl>*/}

                </GridItem>

                <GridItem colSpan={3}>
                  <FormControl>
                    <FormLabel color={"gray.100"}>Bio</FormLabel>
                    <Textarea bg={"gray.100"} rows={10} value={selectedUser.Profile.Bio} readOnly />
                  </FormControl>
                </GridItem>

                {/*<FormControl>*/}
                {/*  <FormLabel>User Type</FormLabel>*/}
                {/*  <Input value={selectedUser.UserType} readOnly />*/}
                {/*</FormControl>*/}
              </Grid>



                {/*/!* Educator Profile Section *!/*/}
                {/*<DrawerHeader>Educator Profile</DrawerHeader>*/}
                {/*<Grid templateColumns="repeat(3, 1fr)" gap={6}>*/}
                {/*  <FormControl>*/}
                {/*    <FormLabel>Price</FormLabel>*/}
                {/*    <Input value={`${selectedUser.EducatorProfile.Price.Amount} ${selectedUser.EducatorProfile.Price.Currency}`} readOnly />*/}
                {/*  </FormControl>*/}
                {/*  /!* Additional fields from the EducatorProfile can be added here *!/*/}
                {/*</Grid>*/}

                {/*/!* Learner Profile Section *!/*/}
                {/*<DrawerHeader>Learner Profile</DrawerHeader>*/}
                {/*<Grid templateColumns="repeat(3, 1fr)" gap={6}>*/}
                {/*  <FormControl>*/}
                {/*    <FormLabel>Needs</FormLabel>*/}
                {/*    <Input value={selectedUser.LearnerProfile.Needs} readOnly />*/}
                {/*  </FormControl>*/}
                {/*  /!* Additional fields from the LearnerProfile can be added here *!/*/}
                {/*</Grid>*/}

              </>
            )}
          </DrawerBody>
          </Card>
          <DrawerFooter>
            {/* Add any buttons you might need in the footer */}
          </DrawerFooter>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
};

export default UserDetailDrawer;


