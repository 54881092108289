import React, { useEffect, useState } from 'react';
import {
    Avatar,
    Box,
    Flex,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    HStack,
    useColorModeValue,
    useDisclosure,
} from '@chakra-ui/react';
import { getCoreRowModel, getSortedRowModel, SortingState, useReactTable } from '@tanstack/react-table';
import Card from 'components/card/Card';
import Menu from 'components/menu/MainMenu';

import { OikosAdmin } from "SDK/OikosAdmin";
import ConversationsDrawer from "./ConversationsDrawer";
import RecordingsDrawer from "./RecordingsDrawer";

type RowObj = OIKOS.ConversationDetails;


const ConversationsTable: React.FC = () => {
    const [sorting, setSorting] = useState<SortingState>([]);
    const textColor = useColorModeValue('secondaryGray.900', 'white');
    const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
    const [loaded, setLoaded] = useState(false);
    const [tableData, setTableData] = useState<RowObj[]>([]);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [selectedConversation, setSelectedConversation] = useState<RowObj | null>(null);

    const admin = new OikosAdmin();

    useEffect(() => {

        if (!loaded) {
            admin.listConversations().then((conversations: OIKOS.ConversationDetails[]) => {
                console.log(conversations)
                setTableData(conversations);
                setLoaded(true);
            }).catch((err: any) => {
                console.log(err);
            });
        }
    }, [loaded]);


    const columns = [
        {
            id: 'EducatorName',
            header: 'Educator',
            cell: (info: { row: { original: RowObj }}) => {
                return(
                  <HStack>
                      <Text color={textColor} fontSize='sm' fontWeight='700'>
                          {info.row.original.EducatorName}
                      </Text>
                  </HStack>
                )}
        },
        {
            id: 'LearnerName',
            header: 'Learner',
            cell: (info: { row: { original: RowObj }}) => <Text color={textColor} fontSize='sm' fontWeight='700'>{info.row.original.LearnerName}</Text>
        },
        {
            id: 'DateUpdated',
            header: 'LastMessage',
            cell: (info: { row: { original: RowObj }}) => {
                let date = new Date(info.row.original.DateUpdated)
                let dateString = date.toLocaleString()
                return <Text color={textColor} fontSize='sm' fontWeight='700'>{dateString}</Text>
            }
        },
        {
            id: 'actions',
            header: 'ACTIONS',
            cell: (info: { row: { original: RowObj }}) => (
              <Flex direction={"row"} gap={4}>
                <ConversationsDrawer
                  conversation={{
                    UniqueName: info.row.original.UniqueName,
                    SID: info.row.original.SID,
                    DateUpdated: info.row.original.DateUpdated
                 }}
                  learnerName={info.row.original.LearnerName}
                  educatorName={info.row.original.EducatorName}
                />
                  <RecordingsDrawer UniqueName={info.row.original.UniqueName} />
              </Flex>
            )
        }
    ];

    const data = [...tableData];
    const table = useReactTable({
        data,
        columns,
        state: {
            sorting,
        },
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        debugTable: true,
    });

    return (
        <Card flexDirection='column' w='100%' px='0px' overflowX={{ sm: 'scroll', lg: 'hidden' }}>
            <Flex px='25px' mb="8px" justifyContent='space-between' align='center'>
                <Text color={textColor} fontSize='22px' fontWeight='700' lineHeight='100%'>
                    Conversations
                </Text>
                <Menu />
            </Flex>
            <Box>
                <Table variant='simple' color='gray.500' mb='24px' mt="12px">
                    <Thead>
                        {table.getHeaderGroups().map((headerGroup) => (
                            <Tr key={headerGroup.id}>
                                {headerGroup.headers.map((header) => {
                                    return (
                                        <Th
                                            key={header.id}
                                            colSpan={header.colSpan}
                                            pe='10px'
                                            borderColor={borderColor}
                                            cursor='pointer'
                                            onClick={header.column.getToggleSortingHandler()}>
                                            {header.column.columnDef.header as React.ReactNode}
                                        </Th>
                                    );
                                })}
                            </Tr>
                        ))}
                    </Thead>
                    <Tbody>
                        {table.getRowModel().rows.slice(0, data.length).map((row, index) => {
                            return (
                                <Tr key={row.id} onClick={() => { setSelectedConversation(row.original); onOpen(); }}>
                                    {row.getVisibleCells().map((cell) => {
                                        return (
                                            <Td
                                                key={cell.id}
                                                fontSize={{ sm: '14px' }}
                                                minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                                                h={'40px'}
                                                borderColor='transparent'>
                                                {(cell.column.columnDef.cell as (cell: any) => React.ReactNode)(cell)}
                                            </Td>
                                        );
                                    })}
                                </Tr>
                            );
                        })}
                    </Tbody>
                </Table>
            </Box>
        </Card>
    );
}

export default ConversationsTable;
